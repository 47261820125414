import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";

const ResourceBody = loadable(() => import("@organisms/ResourceBody"));
const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);

const ResourceSinglePage = ({ data, pageContext }) => {
  const { type, hero, blocks, meta } = data;

  const [, dispatch] = useAppState();
  useEffect(() => {
    dispatch({ type: "setThemeLight" });
  }, []);

  return (
    <PageContainer meta={meta}>
      {type !== "default" && (
        <ResourceBody type={type} hero={hero} blocks={blocks} url={meta.url} />
      )}
      {type === "default" && <PageBuilder blocks={blocks} />}
    </PageContainer>
  );
};

ResourceSinglePage.defaultProps = {};

export default ResourceSinglePage;
